import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Text,
  Input,
  useBreakpointValue,
  useToast,
  VStack
} from '@chakra-ui/react';
import { useRef, useState, useCallback, useEffect } from 'react';
import EmailInput from './EmailInput';
import PhoneInput from './PhoneInput';

function ContactDrawer({ 
  contact, 
  setContact,
  updated,
  setUpdated, 
  isOpen,
  onClose, 
  setContacts 
}) {
  const btnRef = useRef();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const isValid = Object.values(contact).every((value) => value.length >= 3);

  const handleChange = (field) => (e) => {
    setUpdated(true);
    setContact({ ...contact, [field]: e.target.value });
  };

  const handleCustomChange = (field, value) => {
    setUpdated(true);
    setContact({ ...contact, [field]: value });
  };

  const handleSubmit = useCallback(() => {
    setLoading(true);

    let route;
    let message;
    let update;
    if (contact.id) {
      route = `/update/${contact.id}`;
      message = "Os dados do contato foram atualizados com sucesso";
      update = true;
    } else {
      route = "/create";
      message = "O novo contato foi criado com sucesso";
      update = false;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/contact${route}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify(contact)
    })
    .then(response => {
      if (response.ok) {        
        response.json().then(responseJson => {
          toast({
            title: "Contato salvo",
            position: "top",
            description: message,
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          if (update) {
            setContacts(prevContacts => 
              prevContacts.map(contact => 
                contact.id === responseJson.id ? responseJson : contact
              )
            );
          } else {
            setContacts(prevContacts => [...prevContacts, responseJson]);
          }
          setContact({
            firstname: "",
            lastname: "",
            company: "",
            position: "",
            email: "",
            phone: ""
          });
          setLoading(false);
          onClose();
        });
        
      } else {
        response.json().then(responseJson => {
          toast({
            title: "Erro ao salvar contato",
            position: "top",
            description: responseJson.detail,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false);
        })
      }
    })
    .catch(exception => {
      toast({
        title: "Falha na requisição",
        description: "Não foi possível autenticar. A requisição ao servidor falhou.",
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true
      });
      setLoading(false);
    });
  }, [onClose, toast, contact, setContacts, setContact])

  const handleKeyDown = useCallback((event) => {
    if (event.key === "Enter" && isValid) {
      handleSubmit();
    }
  }, [handleSubmit, isValid]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const fontSize = useBreakpointValue({
    base: 20,
    md: 13,
    lg: 15,
  });

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          fontFamily={"'Gantari', sans-serif"}
          bg="var(--ekora-prussian-blue)"
        >
          <DrawerCloseButton color="white" />
          <DrawerHeader
            fontSize={fontSize + 4}
            color="white"
            bg="rgba(0,0,0,.3)"
          >
            Novo Contato
          </DrawerHeader>

          <DrawerBody
            fontSize={fontSize + 1}
            fontWeight={500}
            bg="white"
            color="var(--ekora-prussian-blue)"
          >
            <VStack
              spacing="15px"
              align="left"
            >
              <Box>
                <Text p="5px">Nome</Text>
                <Input
                  fontSize={fontSize}
                  placeholder="Digite aqui..."
                  value={contact.firstname}
                  onChange={handleChange('firstname')}
                />
              </Box>

              <Box>
                <Text p="5px">Sobrenome</Text>
                <Input
                  fontSize={fontSize}
                  placeholder="Digite aqui..."
                  value={contact.lastname}
                  onChange={handleChange('lastname')}
                />
              </Box>

              <Box>
                <Text p="5px">Empresa</Text>
                <Input
                  fontSize={fontSize}
                  placeholder="Digite aqui..."
                  value={contact.company}
                  onChange={handleChange('company')}
                />
              </Box>

              <Box>
                <Text p="5px">Cargo ou posição</Text>
                <Input
                  fontSize={fontSize}
                  placeholder="Digite aqui..."
                  value={contact.position}
                  onChange={handleChange('position')}
                />
              </Box>

              <Box>
                <Text p="5px">Email</Text>
                <EmailInput
                  fontSize={fontSize}
                  placeholder="johndoe@example.com"
                  value={contact.email}
                  onChange={handleCustomChange}
                />
              </Box>

              <Box>
                <Text p="5px">Telefone</Text>
                <PhoneInput
                  fontSize={fontSize}
                  placeholder="(__) _____-____"
                  value={contact.phone}
                  onChange={handleCustomChange}
                />
              </Box>
            </VStack>
          </DrawerBody>

          <DrawerFooter
            bg="rgba(0,0,0,.3)"
          >
            <Button
              variant='outline'
              color="white"
              mr={3}
              onClick={onClose}
              _hover={{ bg: "red.600", color: "white" }}
            >
              Cancelar
            </Button>
            <Button
              colorScheme='orange'
              isDisabled={!(isValid & updated)}
              isLoading={loading}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ContactDrawer;
