// Global Imports
import { Image, Box, Text, useBreakpointValue, Center, VStack, Input, InputGroup, InputLeftElement, Button, useToast } from "@chakra-ui/react";
import { AtSignIcon, LockIcon } from "@chakra-ui/icons";
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

// App-level imports
import "../../App.css";
import whiteLogo from "../../common/assets/LA_1080.png";

function Login ({
  handleLogin
}) {
  const toast = useToast();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRequest = useCallback(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
    .then(response => {
      if (response.ok) {
        handleLogin();
        setLoading(false);
      } else {
        toast({
          title: "Falha no login",
          description: "Não foi possível autenticar. Usuário ou senha incorretos.",
          status: "error",
          duration: 3000,
          position: "top",
          isClosable: true
        });
        setLoading(false);
      }
    })
    .catch(exception => {
      toast({
        title: "Falha no login",
        description: "Não foi possível autenticar. A requisição ao servidor falhou.",
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true
      });
      setLoading(false);
    })
  }, [handleLogin, password, toast, username])

  const handleKeyDown = useCallback((event) => {
    if (event.key === "Enter") {
      handleRequest();
    }
  }, [handleRequest]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);


  const fontSize = useBreakpointValue({
    base: 20,
    md: 16,
    lg: 20,
  });

  const logoHeight = useBreakpointValue({
    base: "40px",
    md: "40px",
    lg: "55px"
  })

  return (
    <Center
      height="100%"
      width="100%"
      as={motion.div}
      opacity={0.01}
      animate={{opacity: 1}}
    >
      <Box
        margin={useBreakpointValue({
          base: "0", 
          md: "20px"
        })}
        rounded={useBreakpointValue({
          base: "0", 
          md: "5px"
        })}
        width={useBreakpointValue({
          base: "100vw",
          md: "300px",
          lg: "370px"
        })}
      >
        <VStack
          align="left"
          spacing="5px"
          padding="20px"
          fontSize={fontSize}
          color="var(--ekora-prussian-blue)"
        >
          <Box
            as={motion.div}
            height={0}
            opacity={0.01}
            animate={{opacity: 1, height: logoHeight}}
            transition={{duration: 3}}
            overflow="hidden"
          >
            <Image
              height={logoHeight}
              src={whiteLogo}
            />
          </Box>
          <Text
            fontWeight={400}
            fontSize={fontSize -2}
          >
            Ambiente de Administração do <b>KORE</b>
          </Text>

          <Box>
            <Text
              fontWeight={300}
              fontSize={fontSize - 2}
            >
              Usuário
            </Text>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <AtSignIcon  color="var(--ekora-prussian-blue)" />
              </InputLeftElement>
              <Input
                type="text"
                bg="var(--bg-input)"
                border="none"
                className="inner-shadow"
                onChange={(e) => setUsername(e.target.value)}
                sx={{
                  _focus: {
                    outline: "none",
                    boxShadow: "inset 0 1px 5px rgba(0, 0, 10, 0.05)",
                    borderColor: "transparent"
                  },
                }}
              />
            </InputGroup>
          </Box>

          <Box>
            <Text
              fontWeight={300}
              fontSize={fontSize - 2}
            >
              Senha
            </Text>
            
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <LockIcon color="var(--ekora-prussian-blue)" />
              </InputLeftElement>
              <Input
                type="password"
                bg="var(--bg-input)"
                border="none"
                className="inner-shadow"
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  _focus: {
                    outline: "none",
                    boxShadow: "inset 0 1px 5px rgba(0, 0, 10, 0.05)",
                    borderColor: "transparent"
                  },
                }}
              />
              </InputGroup>
          </Box>

          <Button
            justifyContent="left"
            fontWeight={400}
            marginTop="10px"
            bg="var(--ekora-cerulean)"
            color="var(--text-color)"
            _hover={{bg: "var(--ekora-tangerine)"}}
            fontSize={fontSize - 2}
            isLoading={loading}
            onClick={handleRequest}
          >
            Login
          </Button>
        
        </VStack>
      </Box>

      <Box position="absolute" bottom="20px" left="20px">
        <Button
          bg="var(--ekora-tangerine-alt)"
          _hover={{bg: "var(--ekora-tangerine)"}}
          onClick={() => navigate('/')}
          fontSize={fontSize - 3}
        >
          <FontAwesomeIcon width={fontSize} icon={faArrowLeft}/>
          <Text ml="5px">Ambiente de aplicação</Text>
        </Button>
      </Box>

    </Center> 
  )
}

export default Login;