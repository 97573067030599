// Global Imports
import { Flex, useBreakpointValue, Box, Image, Text, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

// App-level imports
import '../../App.css'
import whiteLogo from "../../common/assets/LA_1080.png";

function DescriptionPage ({
  nextStep
}) {
  const fontSize = useBreakpointValue({
    base: 20,
    md: 16,
    lg: 20,
  });

  const logoHeight = useBreakpointValue({
    base: "40px",
    md: "40px",
    lg: "55px"
  })

  return (
    <Flex 
      as={motion.div}
      opacity={0.01}
      animate={{opacity: 1}}
      transition={{duration: 3}}
      flexDirection="column"
    >
      <Box
        as={motion.div}
        height={0}
        opacity={0.01}
        animate={{opacity: 1, height: logoHeight}}
        transition={{duration: 3}}
        overflow="hidden"
      >
        <Image
          height={logoHeight}
          src={whiteLogo}
        />
      </Box>
      <Text
        fontWeight={400}
        fontSize={fontSize + 4}
        mt="20px"
      >
        Seja bem-vindo ao <b>KORE</b>
      </Text>

      <Box
        mt="10px"
        padding="10px"
        rounded="5px"
        bg="rgba(0,0,0,0.1)"
        fontWeight={300}
        maxW="600px"
        maxH="500px"
        overflow="auto"
        fontSize={fontSize -2}
        textAlign="justify"
      >
        O diagnóstico KORE (Diagnóstico das chaves para excelência organizacional) da Ekora é uma ferramenta estratégica que fornece insights essenciais e pontos de alavancagem para a estratégia de negócios, com foco na avaliação do nível de prontidão da organização para construção de resultados.
        <br></br><br></br>
        O KORE é composto por 8 variáveis — <b>Pessoas, Engajamento, Negócios, Estratégia, Gestão, Mudança, Cultura, e Mentalidade</b> — e proporciona uma visão clara dos pontos e áreas que precisam de aprimoramento para impulsionar a organização rumo à excelência.
      </Box>
      
      <Button
        mt="20px"
        bg="var(--ekora-cerulean)"
        color="white"
        _hover={{bg: "var(--ekora-cerulean-alt)"}}
        onClick={nextStep}
      >
        <Text mr="5px">Estou de acordo</Text>
        <FontAwesomeIcon width={fontSize} icon={faArrowRight}/>
      </Button>
    </Flex>
  )
}

export default DescriptionPage;
