// Global Imports
import { Flex, useBreakpointValue, HStack, Input, Box, Image, Text, Center, List, ListItem, ListIcon, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// App-level imports
import '../../App.css'
import whiteLogo from "../../common/assets/LB_1080.png";


function LandingPage({ 
  adminLogged
}) {
  
  const fontSize = useBreakpointValue({
    base: 20,
    md: 16,
    lg: 20,
  });

  const logoHeight = useBreakpointValue({
    base: "40px",
    md: "40px",
    lg: "55px"
  })

  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState('');
  const handleChange = (event) => {
    const { value } = event.target;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
    setInputValue(filteredValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleButtonClick();
    }
  };

  const handleButtonClick = () => {
    navigate('/survey?code=' + inputValue);
  };


  return (
    <Center
      h="100vh"
      w="100vw"
      bg="var(--bg-color)"
      color="var(--text-color)"
      padding="20px"
    >
      <Flex flexDirection="column">
        <Box
          as={motion.div}
          height={0}
          opacity={0.01}
          animate={{opacity: 1, height: logoHeight}}
          transition={{duration: 3}}
          overflow="hidden"
        >
          <Image
            height={logoHeight}
            src={whiteLogo}
          />
        </Box>
        <Text
          fontWeight={400}
          fontSize={fontSize}
        >
          Ambiente de Aplicação do <b>KORE</b>
        </Text>

        <Box
          mt="10px"
          padding="10px"
          rounded="5px"
          bg="rgba(0,0,0,0.2)"
          fontWeight={300}
          maxW="600px"
          fontSize={fontSize -2}
          className="shadowed"
        >
          Seja bem-vindo! Parece que você está tentando acessar a aplicação do <b>KORE</b>, da Ekora.
          </Box>
        
        <Box
          mt="10px"
          padding="10px"
          rounded="5px"
          bg="rgba(0,0,0,0.2)"
          fontWeight={300}
          maxW="600px"
          fontSize={fontSize -2}
          className="shadowed"
        >
          Para isso, você deve: 
          <List>
            <ListItem>
              <ListIcon as={ArrowForwardIcon} color='var(--ekora-tangerine)' />
                Acessar o link que foi fornecido pela nossa equipe;
            </ListItem>
            <ListItem> <b>OU</b> </ListItem>
            <ListItem>
              <ListIcon as={ArrowForwardIcon} color='var(--ekora-tangerine)' />
              Informar o código da aplicação aqui:
              <HStack
                padding="10px"
              >
                <Input 
                  type="text" 
                  value={inputValue}
                  onChange={handleChange} 
                  onKeyDown={handleKeyDown}
                  placeholder="Digite o código..." 
                />
                <Button
                  bg="var(--ekora-cerulean)"
                  _hover={{bg: "var(--ekora-tangerine)"}}
                  onClick={handleButtonClick}
                >
                  <FontAwesomeIcon 
                    width={fontSize}
                    icon={faArrowRight} 
                    style={{
                      color: "white",
                      padding: "10px", 
                      borderRadius: "5px"
                    }}
                  />
                </Button>
              </HStack>
              
            </ListItem>
          </List>
        </Box>
      </Flex>
  
      <Box position="absolute" bottom="20px" left="20px">
        <Button
          bg="var(--ekora-cerulean)"
          color="white"
          _hover={{bg: "var(--ekora-cerulean-alt)"}}
          onClick={() => navigate('/admin')}
          fontSize={fontSize - 4}
        >
          <Text mr="5px">Ambiente de administração</Text>
          <FontAwesomeIcon width={fontSize} icon={faArrowRight}/>
        </Button>
      </Box>

    </Center>
  )
}

export default LandingPage;