import { Box, Text, Input } from "@chakra-ui/react";
import React, { useState } from 'react';

function EmailInput({ 
    fontSize = 12,
    placeholder = "johndoe@example.com",
    bg = null,
    value = '',
    border = "solid #cccccc 1px",
    onChange = null 
}) {
  const [email, setEmail] = useState(value);
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    if (email && !validateEmail(e.target.value)) {
      setError('Endereço de email inválido');
      onChange('email', '');
    } else {
      setError('');
      onChange('email', e.target.value);
    }
    setEmail(e.target.value);
  };

  const handleBlur = () => {
    if (email && !validateEmail(email)) {
      setError('Endereço de email inválido');
      onChange('email', '');
    } else {
      setError('');
      onChange('email', email);
    }
  };

  return (
    <Box>
      <Input 
        fontSize={fontSize} 
        placeholder={placeholder} 
        bg={bg}
        border={border}
        value={email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {error && <Text 
        fontSize={fontSize} 
        padding="10px" 
        pl="16px" 
        marginTop="5px"
        marginBottom="-15px" 
        color="white" 
        background="red.600" 
        rounded="5px"
      >{error}</Text>}
    </Box>
  );
};

export default EmailInput;