import { useEffect } from "react";

function useBeforeUnload(enabled) {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (enabled) {
        const message = "Você tem mudanças não salvas. Tem certeza de que deseja sair?";
        event.preventDefault();
        event.returnValue = message; 
        return message;
      }
    };

    const handlePopState = () => {
      if (enabled) {
        const message = "Você tem mudanças não salvas. Tem certeza de que deseja sair?";
        if (!window.confirm(message)) {
          window.history.pushState(null, "", window.location.href);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);
    
    // Push initial state to history to manage popstate
    window.history.pushState(null, "", window.location.href);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [enabled]);
}

export default useBeforeUnload;
