// Global Imports
import { 
  Flex, 
  useBreakpointValue, 
  Box, 
  Image, 
  Center, 
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  VStack,
  HStack,
  Text,
  Button,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton, 
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import queryString from 'query-string';

// App-level imports
import '../../App.css'
import whiteLogo from "../../common/assets/LB_1080.png";
import DescriptionPage from "./DescriptionPage";
import SetupPage from "./SetupPage";
import { useState, useEffect } from "react";
import QuestionPage from "./QuestionPage";
import NotFoundSurvey from "./NotFoundSurvey";
import ThankYouPage from "./ThankYouPage"

function Survey() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { code: surveyCode } = queryString.parse(location.search);
  const [isValid, setValid] = useState(false);
  const [questions, setQuestions] = useState([]);
  const { isOpen: clOpen, onOpen: clnOpen, onClose: clnClose } = useDisclosure();

  const steps = [
    { title: 'Introdução', description: 'Descrição do KORE' },
    { title: 'Setup', description: 'Informações pessoais' },
    { title: 'Aplicação', description: `Responda as ${questions.length} perguntas` },
    { title: 'Encerramento', description: 'Envie as suas respostas' }
  ]

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/apply/${surveyCode}`, {
      method: "GET",
      credentials: "include"
    })
    .then( response => {
      if (response.ok) {
        response.json().then(responseJson => {
          setQuestions(responseJson.map((item) => {
            return { ...item, value: 1 };
          }));
        });
        setValid(true);
      } else {
        setValid(false);
      }
    })
  }, [surveyCode]);

  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/save/${surveyCode}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        user: user,
        responses: questions.map((item) => ({
          question_id: item.id,
          value: item.value
        }))
      })  
    })
    .then(response => {
      if (response.ok) {
        toast({
          status: "success",
          title: "Respostas salvas",
          description: "As suas respostas foram enviadas com sucesso.",
          duration: 3000,
          position: "top"
        })
        setActiveStep(activeStep + 1)
      } else {
        response.json().then(responseJson => {
          toast({
            status: "error",
            title: "Erro ao enviar respostas",
            description: responseJson.detail,
            duration: 5000,
            position: "top"
          })
        })
      }  
    })
    .catch(exception => {
      toast({
        status: "error",
        title: "Erro ao enviar respostas",
        description: "A requisição ao servidor falhou",
        duration: 5000,
        position: "top"
      })
    })
  }

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  })

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    area: ""
  });

  const stepPages = {
    0: <DescriptionPage nextStep={() => setActiveStep(activeStep + 1)}/>,
    1: <SetupPage nextStep={() => setActiveStep(activeStep + 1)} user={user} setUser={setUser}/>,
    2: <QuestionPage handleSubmit={handleSubmit} questions={questions} setQuestions={setQuestions} />, 
    3: <ThankYouPage />
  }

  const fontSize = useBreakpointValue({
    base: 20,
    md: 16,
    lg: 20,
  });

  const survey = (
    <Flex
      as={motion.div}
      opacity={0.01}
      animate={{opacity: 1}}
      bg="gray.100"
      height="100%"
      width="100%"
      overflow="hidden"
    > 
      <VStack
        flex={1}
        className="shadowed"
        bg="var(--ekora-prussian-blue)"
        color="white"
        overflow="hidden"
      > 
        <VStack
          height="100%"
          width="100%"
        >
          <Box
            padding="16px"
            width="100%"
            bg="rgba(0,0,0,0.2)"
          >
            <Image 
              width={useBreakpointValue({
                base: "0px",
                md: "50px",
                lg: "70px"
              })}
              src={whiteLogo}
            />
          </Box>

          <Stepper 
            index={activeStep}
            padding="24px" 
            orientation='vertical'
            overflow="hidden"
            colorScheme='orange'
            minHeight="28%"
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon/>}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink='0'>
                  <StepTitle fontSize={fontSize - 3}>{step.title}</StepTitle>
                  <StepDescription fontSize={fontSize - 5} color="gray.200">{step.description}</StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </VStack>

        <HStack
          width="100%"
          bg="rgba(0,0,0,0.2)"
        >
          <Text width="100%" marginLeft="16px" color="rgba(255,255,255,0.4)" fontSize={fontSize - 4}>
            KORE v1.0
          </Text>
          <Button
            margin="8px"
            marginRight="16px"
            variant="ghost"
            color="white"
            fontSize={fontSize - 4}
            _hover={{ bg: "red.600" }}
            onClick={activeStep < 1 ? () => navigate('/') : clnOpen}
          >
            Voltar
          </Button>
        </HStack>
      </VStack>

      <Center
        position="relative"
        height="100vh"
        width={useBreakpointValue({
          base: "100%",
          sm: "calc(100% - 250px)",
          lg: "calc(100% - 300px)"
        })}
        overflow="hidden"
      >
        {stepPages[activeStep]}
        {activeStep !== 3 && (
        <Box position="absolute" bottom="20px" left="20px">
          <Button
            bg="red.500"
            color="white"
            _hover={{bg: "red.700"}}
            fontSize={fontSize - 5}
            isDisabled={activeStep < 1}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            <FontAwesomeIcon width={fontSize} icon={faArrowLeft}/>
            <Text ml="5px">Passo anterior</Text>
          </Button>
        </Box>
        )}
      </Center>
    </Flex>
  );

  return (
    <>
      {isValid ? survey : <NotFoundSurvey/>}

      <Modal isOpen={clOpen} onClose={clnClose}>
        <ModalOverlay />
        <ModalContent
          fontFamily="'Gantari', sans-serif"
        >
          <ModalHeader>Sair sem salvar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Deseja sair sem salvar suas respostas?
            Todo o seu progresso será perdido.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" bg="red.200" mr={3} onClick={() => navigate("/")}>
              Sair
            </Button>
            <Button 
              variant="ghost"
              _hover={{bg: "var(--ekora-cerulean)", color: "white"}} 
              onClick={() => clnClose()}
            >
              Voltar ao questionário
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Survey;
