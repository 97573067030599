import { Box, Button, HStack, Image, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import whiteLogo from "../../common/assets/LB_1080.png"
import ContactDrawer from "./ContactDrawer";
import SurveyDrawer from "./SurveyDrawer";


function Sidenav({ 
  handleLogout, 
  contacts, 
  setContacts, 
  setSurveys,
  svIsOpen,
  svOpen,
  svClose,
  svUpdated,
  setSvUpdated,
  setSurveyData,
  surveyData,
  setContactData,
  contactData,
  cttIsOpen,
  cttOpen,
  cttClose,
  cttUpdated,
  setCttUpdated
}) {
  const fontSize = useBreakpointValue({
    base: 20,
    md: 13,
    lg: 15,
  });
  
  return (
    <Box
      height="100%"
      display={useBreakpointValue({
        base: "none",
        md: "block"
      })}
      width={useBreakpointValue({
        base: "0px",
        md: "150px",
        lg: "190px"
      })}
      bg="var(--ekora-prussian-blue)"
      className="shadowed"
      fontSize={fontSize}
    >
      <ContactDrawer 
        contact={contactData}
        setContact={setContactData}
        isOpen={cttIsOpen}
        onOpen={cttOpen} 
        onClose={cttClose}
        setContacts={setContacts}
        updated={cttUpdated}
        setUpdated={setCttUpdated}
      />

      <SurveyDrawer
        survey={surveyData}
        setSurvey={setSurveyData}
        isOpen={svIsOpen} 
        onOpen={svOpen} 
        onClose={svClose}
        contacts={contacts}
        setSurveys={setSurveys}
        updated={svUpdated}
        setUpdated={setSvUpdated}
      />

      <VStack
        height="100%"
        spacing={0}
        justifyContent="space-between"
      >
        <Box
          padding="16px"
          width="100%"
          bg="rgba(0,0,0,0.2)"
        >
          <Image 
            width={useBreakpointValue({
              base: "0px",
              md: "50px",
              lg: "70px"
            })}
            src={whiteLogo}
          />
        </Box>

        <VStack
          width="100%"
          spacing={0}
          flex="1"
        >
          <Button
            width="100%"
            justifyContent="left"
            transition="0.1s"
            bg="none"
            fontWeight={400}
            color="var(--text-color)"
            rounded={0}
            _hover={{ bg: "rgba(255,255,255,0.1)", color: "var(--ekora-tangerine)"}}
            fontSize={fontSize}
            onClick={() => {
              setContactData({
                firstname: "",
                lastname: "",
                company: "",
                position: "",
                email: "",
                phone: ""
              })
              cttOpen();
            }}
          >
            <HStack
              h="100%"
              alignItems="center"
            >
              <FontAwesomeIcon width={`${fontSize}px`} icon={faUserPlus}/>
              <Text>Novo contato</Text>
            </HStack>
          </Button>

          <Button
            width="100%"
            justifyContent="left"
            transition="0.1s"
            bg="none"
            fontWeight={400}
            color="var(--text-color)"
            rounded={0}
            _hover={{ bg: "rgba(255,255,255,0.1)", color: "var(--ekora-tangerine)"}}
            fontSize={fontSize}
            onClick={svOpen}
          >
            <HStack
              h="100%"
              alignItems="center"
            >
              <FontAwesomeIcon width={`${fontSize}px`} icon={faFilePen}/>
              <Text>Nova aplicação</Text>
            </HStack>
          </Button>
        </VStack>
        
        <HStack
          width="100%"
          bg="rgba(0,0,0,0.2)"
        >
          <Text width="100%" paddingLeft="16px" color="rgba(255,255,255,0.4)" fontSize={fontSize - 1}>
            KORE v1.0
          </Text>
          <Button
            margin="8px"
            marginRight="16px"
            variant="ghost"
            color="white"
            fontSize={fontSize - 1}
            _hover={{ bg: "red.600" }}
            onClick={handleLogout}
          >
            Voltar
          </Button>
        </HStack>
      </VStack>
    </Box>
  )
}

export default Sidenav;