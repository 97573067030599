// Global Imports
import { Flex, useBreakpointValue, Box, Input, Text, Select, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import EmailInput from "../../admin/blocks/EmailInput";
import useBeforeUnload from "../functions/useBeforeUnload";

function SetupPage ({
  user,
  setUser,
  nextStep = null
}) {
  useBeforeUnload(true);

  const fontSize = useBreakpointValue({
    base: 20,
    md: 16,
    lg: 20,
  });

  const handleChange = (field) => (e) => {
    setUser({ ...user, [field]: e.target.value });
  };

  const handleCustomChange = (field, value) => {
    setUser({ ...user, [field]: value });
  };

  const isValid = Object.values(user).every((value) => value.length >= 3);

  return(
    <Flex 
      as={motion.div}
      opacity={0.01}
      animate={{opacity: 1}}
      transition={{duration: 3}}
      flexDirection="column"
      color="var(--ekora-prussian-blue)"
    >
      <Text
        fontWeight={400}
        fontSize={fontSize + 4}
        mt="20px"
        mb="10px"
      >
        Nos conte um pouco sobre <b>você</b>
      </Text>

      <Box marginTop="15px">
        <Text p="5px" fontWeight={500}>Nome</Text>
        <Input
          fontSize={fontSize - 5}
          placeholder="Digite aqui..."
          value={user.firstname}
          bg="rgba(0,0,0,0.1)"
          onChange={handleChange('firstname')}
        />
      </Box>

      <Box marginTop="15px">
        <Text p="5px" fontWeight={500}>Sobrenome</Text>
        <Input
          fontSize={fontSize - 5}
          placeholder="Digite aqui..."
          value={user.lastname}
          bg="rgba(0,0,0,0.1)"
          onChange={handleChange('lastname')}
        />
      </Box>

      <Box marginTop="15px">
        <Text p="5px">Email</Text>
        <EmailInput
          fontSize={fontSize - 5}
          placeholder="johndoe@example.com"
          border="none"
          value={user.email}
          bg="rgba(0,0,0,0.1)"
          onChange={handleCustomChange}
        />
      </Box>

      <Box marginTop="15px">
        <Text p="5px" fontWeight={500}>Área de atuação</Text>
        <Select
          fontSize={fontSize - 5}
          placeholder="Selecione uma opção"
          value={user.area}
          bg="rgba(0,0,0,0.1)"
          onChange={handleChange('area')}
        >
          <option value="administrativo">Administrativo</option>
          <option value="comercial">Comercial</option>
          <option value="financeiro">Financeiro</option>
          <option value="marketing">Marketing</option>
          <option value="recursos_humanos">Recursos Humanos</option>
          <option value="tecnologia">Tecnologia</option>
          <option value="operacional">Operacional</option>
          <option value="juridico">Jurídico</option>
          <option value="vendas">Vendas</option>
          <option value="logistica">Logística</option>
          <option value="compras">Compras</option>
          <option value="desenvolvimento_produto">Desenvolvimento de Produto</option>
          <option value="servico_cliente">Serviço ao Cliente</option>
          <option value="outros">Outro</option>
        </Select>
      </Box>

      
      <Button
        mt="20px"
        bg="var(--ekora-cerulean)"
        color="white"
        _hover={{bg: "var(--ekora-cerulean-alt)"}}
        onClick={nextStep}
        isDisabled={!isValid}
      >
        <Text mr="5px">Ir para o questionário</Text>
        <FontAwesomeIcon width={fontSize} icon={faArrowRight}/>
      </Button>
    </Flex>
  );
}

export default SetupPage;