import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Box,
    Text,
    Input,
    useBreakpointValue,
    useToast,
    VStack,
    Select,
    HStack
  } from '@chakra-ui/react';
import { faCalendarAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
  
  function SurveyDrawer({ 
    updated, 
    setUpdated, 
    isOpen, 
    onClose, 
    contacts, 
    setSurveys, 
    survey, 
    setSurvey
  }) {
    const btnRef = useRef();
    const toast = useToast();

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const [loading, setLoading] = useState(false);
    
    const handleSubmit = () => {
      setLoading(true);

      let route;
      let message;
      let update;
      if (survey.id) {
        route = `/update/${survey.id}`;
        message = "Os dados da aplicação foram atualizados com sucesso";
        update = true;
      } else {
        route = "/create";
        message = "A nova aplicação foi criada com sucesso, seu link está na tabela de aplicações";
        update = false;
      }

      fetch(`${process.env.REACT_APP_BACKEND_URL}/survey${route}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify(survey)
      })
      .then(response => {
        if (response.ok) {          
          response.json().then(responseJson => {
            if (update) {
              setSurveys(prevSurveys => 
                prevSurveys.map(survey => 
                  survey.id === responseJson.id ? responseJson : survey
                )
              );
            } else {
              setSurveys(prevSurveys => [...prevSurveys, responseJson]);
            }
            setSurvey({
              contact: "",
              expires_in: ""
            });
            setLoading(false);
            toast({
              title: 'Aplicação salva',
              position: 'top',
              description: message,
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
            onClose();
          });
          
        } else {
          response.json().then(jsonData => {
            toast({
              title: "Erro ao criar aplicação",
              position: "top",
              description: jsonData["detail"],
              status: "error",
              duration: 4000,
              isClosable: true,
            });
            setLoading(false);
          });
        }
      })
      .catch(exception => {
        toast({
          title: "Falha na requisição",
          description: "Não foi possível autenticar. A requisição ao servidor falhou.",
          status: "error",
          duration: 3000,
          position: "top",
          isClosable: true
        });
        setLoading(false);
      });
    };
  
    const fontSize = useBreakpointValue({
      base: 20,
      md: 13,
      lg: 15,
    });

    const isFormValid = survey.contact_id && survey.expires_in;

    function formatDate(datetimeString) {
      const date = new Date(datetimeString);
  
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
  
      return `${year}-${month}-${day}`;
    }

    return (
      <>
        <Drawer
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent
            fontFamily={"'Gantari', sans-serif"}
            bg="var(--ekora-prussian-blue)"
          >
            <DrawerCloseButton color="white" />
            <DrawerHeader
              fontSize={fontSize + 4}
              color="white"
              bg="rgba(0,0,0,.3)"
            >
              Nova Aplicação
            </DrawerHeader>
  
            <DrawerBody
              fontSize={fontSize + 1}
              fontWeight={500}
              bg="white"
              color="var(--ekora-prussian-blue)"
            >
              <VStack
                spacing="15px"
                align="left"
              >
                <Box>
                  <Text p="5px">Selecione o contato da empresa onde o KORE será aplicado</Text>
                  <HStack>
                    <FontAwesomeIcon 
                      width={fontSize}
                      icon={faUserFriends} 
                      style={{
                        color: "white",
                        background: "var(--ekora-cerulean)", 
                        padding: "10px", 
                        borderRadius: "5px"
                      }}
                    />
                    <Select 
                      placeholder="Selecione um contato" 
                      onChange={(e) => {
                        setUpdated(true);
                        setSurvey(prevState => ({
                          ...prevState,
                          contact_id: e.target.value
                        }));
                      }}
                    >
                      {contacts.map(contact => (
                        <option 
                          key={contact.firstname + contact.lastname} 
                          value={contact.id}
                          selected={contact.id === survey.contact_id}
                        >
                          {contact.firstname} {contact.lastname}
                        </option>
                      ))}
                    </Select>
                  </HStack>
                </Box>

                <Box>
                  <Text p="5px">A aplicação será válida até</Text>
                  <HStack>
                    <FontAwesomeIcon 
                      width={fontSize}
                      icon={faCalendarAlt} 
                      style={{
                        color: "white",
                        background: "var(--ekora-cerulean)", 
                        padding: "10px", 
                        borderRadius: "5px"
                      }}
                    />
                    <Input 
                      min={getTodayDate()} 
                      type="date"
                      value={formatDate(survey.expires_in)}
                      onChange={(e) => {
                        setUpdated(true);
                        setSurvey(prevState => ({
                          ...prevState,
                          expires_in: e.target.value
                        }));
                      }} 
                    />
                  </HStack>
                </Box>
  
              </VStack>
            </DrawerBody>
  
            <DrawerFooter
              bg="rgba(0,0,0,.3)"
            >
              <Button
                variant='outline'
                color="white"
                mr={3}
                onClick={onClose}
                _hover={{ bg: "red.600", color: "white" }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme='orange'
                isDisabled={!(isFormValid && updated)}
                isLoading={loading}
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    );
  }
  
  export default SurveyDrawer;
  