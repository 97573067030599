// Global Imports
import { Flex, useBreakpointValue } from "@chakra-ui/react";

// App-level imports
import "../../App.css";
import Login from "../blocks/Login";
import MainPage from "./MainPage";

function AdminPage({ 
  adminLogged,
  handleLogin,
  handleLogout
}) {
  const page = useBreakpointValue({
    base: <></>,
    sm: <MainPage handleLogout={handleLogout}/>
  })

  return (
    <Flex
      height="100vh"
      width="100vw"
      bg="white"
    >
      { adminLogged ? 
        page  
        : 
        <Login handleLogin={handleLogin}/>
      }
    </Flex>
  )
}

export default AdminPage;