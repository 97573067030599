// Global Imports
import { Flex, useBreakpointValue, Box, Image, Text, Center, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

// App-level imports
import '../../App.css'
import whiteLogo from "../../common/assets/LB_1080.png";

function NotFoundSurvey() {

  const fontSize = useBreakpointValue({
    base: 20,
    md: 16,
    lg: 20,
  });

  const logoHeight = useBreakpointValue({
    base: "40px",
    md: "40px",
    lg: "55px"
  })

  const navigate = useNavigate();

  return (
    <Center
      h="100vh"
      w="100vw"
      bg="var(--bg-color)"
      color="var(--text-color)"
      padding="20px"
    >
      <Flex flexDirection="column">
        <Box
          as={motion.div}
          height={0}
          opacity={0.01}
          animate={{opacity: 1, height: logoHeight}}
          transition={{duration: 3}}
          overflow="hidden"
        >
          <Image
            height={logoHeight}
            src={whiteLogo}
          />
        </Box>
        <Text
          fontWeight={400}
          fontSize={fontSize + 4}
          mt="20px"
        >
          404 - Aplicação não encontrada ou expirada
        </Text>

        <Box
          mt="10px"
          padding="10px"
          rounded="5px"
          bg="rgba(0,0,0,0.2)"
          fontWeight={300}
          maxW="600px"
          fontSize={fontSize -2}
          className="shadowed"
        >
          A aplicação que você está procurando não existe ou já expirou.
        </Box>

        <Box
          mt="10px"
          padding="10px"
          rounded="5px"
          bg="rgba(0,0,0,0.2)"
          fontWeight={300}
          maxW="600px"
          fontSize={fontSize -2}
          className="shadowed"
        >
          Caso você esteja tentando responder ao questionário KORE, <b style={{color: "var(--ekora-tangerine)"}}>certifique-se de que o código da sua aplicação está correto e ainda é válido.</b>
        </Box>

        <Box
          mt="10px"
          padding="10px"
          rounded="5px"
          bg="rgba(0,0,0,0.2)"
          fontWeight={300}
          maxW="600px"
          fontSize={fontSize -2}
          className="shadowed"
        >
          Caso o problema persistir, contate-nos para que possamos lhe ajudar o mais rápido possível.
        </Box>
        
        <Button
          mt="20px"
          bg="var(--ekora-cerulean)"
          color="white"
          _hover={{bg: "var(--ekora-cerulean-alt)"}}
          onClick={() => navigate('/')}
        >
          <Text mr="5px">Voltar para ambiente de aplicação</Text>
          <FontAwesomeIcon width={fontSize} icon={faArrowRight}/>
        </Button>
      </Flex>
    </Center>
  )
}

export default NotFoundSurvey;
