import { 
  useBreakpointValue, 
  Text, 
  Box, 
  Table, 
  Thead, 
  Th, 
  Tbody, 
  TableContainer, 
  Tr, 
  Td, 
  Flex,
  Button, 
  HStack, 
  useToast,
  useDisclosure
} from "@chakra-ui/react";

import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit, faListDots, faTrashAlt, faUsers, faCopy, faLink, faDownload } from "@fortawesome/free-solid-svg-icons";

import Sidenav from "../blocks/Sidenav";

function MainPage ({
  handleLogout
}) {
  const toast = useToast();

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [reportLoading, setReportLoading] = useState(false);

  const [surveys, setSurveys] = useState([]);
  const [svDeleting, setSvDeleting] = useState(false);
  const [svClosing, setSvClosing] = useState(false);
  const [surveysLoading, setSurveysLoading] = useState(false);
  const [svUpdated, setSvUpdated] = useState(false);  
  const [surveyEdit, setSurveyEdit] = useState({
    contact_id: "",
    expires_in: getTodayDate()
  })

  const { isOpen: cttIsOpen, onOpen: cttOpen, onClose: cttClose } = useDisclosure();
  const { isOpen: svIsOpen, onOpen: svOpen, onClose: svClose } = useDisclosure();

  const [contacts, setContacts] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(false);
  const [cttDeleting, setCttDeleting] = useState(false);
  const [cttUpdated, setCttUpdated] = useState(false);
  const [contactEdit, setContactEdit] = useState({
    firstname: "",
    lastname: "",
    company: "",
    position: "",
    email: "",
    phone: ""
  });

  const handleContactEdit = (id) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/contact/get/${id}`, {
      method: "GET",
      credentials: "include",
    })
    .then(response => {
      if (response.ok) {
        response.json().then(responseJson => {
          setContactEdit(responseJson);
          setCttUpdated(false);
          cttOpen();
        })
      }
    })
    .catch(exception => {
      toast({
        status: "error",
        title: "Erro na requisição ao sistema",
        description: "Falha ao fazer a requisição ao sistema.",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
    })
  }

  const handleContactDelete = (id) => {
    setCttDeleting(true);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/contact/update/${id}?delete=true`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(contacts.find(contact => contact.id === id))
    })
    .then(response => {
      if (response.ok) {
        toast({
          status: "success",
          title: "Contato excluído",
          description: "O contato e todos os seus dados foram removidos com sucesso.",
          duration: 3000,
          isClosable: true,
          position: "top"
        });
        setCttDeleting(false);
        setContacts(prevContacts => prevContacts.filter(contact => contact.id !== id));
      }
    })
    .catch(exception => {
      toast({
        status: "error",
        title: "Erro na requisição ao sistema",
        description: "Falha ao fazer a requisição ao sistema.",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
      setCttDeleting(false);
    })
  }

  const handleSurveyEdit = (id) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/get/${id}`, {
      method: "GET",
      credentials: "include",
    })
    .then(response => {
      if (response.ok) {
        response.json().then(responseJson => {
          setSurveyEdit(responseJson);
          setSvUpdated(false);
          svOpen();
        })
      }
    })
    .catch(exception => {
      toast({
        status: "error",
        title: "Erro na requisição ao sistema",
        description: "Falha ao fazer a requisição ao sistema.",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
    })
  }

  const handleSurveyClose = (id) => {
    setSvClosing(true);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/update/${id}?close=true`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(surveys.find(survey => survey.id === id))
    })
    .then(response => {
      if (response.ok) {
        response.json().then(responseJson => {
          toast({
            status: "success",
            title: "Aplicação encerrada",
            description: "Aplicação encerrada com sucesso, o código e o link não serão mais válidos.",
            duration: 3000,
            isClosable: true,
            position: "top"
          });
          setSvClosing(false);
          setSurveys(prevSurveys => 
            prevSurveys.map(survey => 
              survey.id === responseJson.id ? responseJson : survey
            )
          );
        });
      }
    })
    .catch(exception => {
      toast({
        status: "error",
        title: "Erro na requisição ao sistema",
        description: "Falha ao fazer a requisição ao sistema.",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
      setSvClosing(false);
    })
  }

  const handleSurveyDelete = (id) => {
    setSvDeleting(true);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/update/${id}?delete=true`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(surveys.find(survey => survey.id === id))
    })
    .then(response => {
      if (response.ok) {
        response.json().then(responseJson => {
          toast({
            status: "success",
            title: "Aplicação removida",
            description: "A aplicação e seus dados foram excluídos com sucesso.",
            duration: 3000,
            isClosable: true,
            position: "top"
          });
          setSurveys(prevSurveys => 
            prevSurveys.filter(survey => survey.id !== id)
          );
          setSvDeleting(false);
        });
      }
    })
    .catch(exception => {
      toast({
        status: "error",
        title: "Erro na requisição ao sistema",
        description: "Falha ao fazer a requisição ao sistema.",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
      setSvDeleting(false);
    })
  }

  const handleContactLoad = () => {
    setContactsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/contact/list`, {
      method: "GET",
      credentials: "include"
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    })
    .then(responseJson => {
      setContacts(responseJson);
      setContactsLoading(false);
    })
  }

  const handleSurveyLoad = () => {
    setSurveysLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/list`, {
      method: "GET",
      credentials: "include"
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    })
    .then(responseJson => {
      setSurveys(responseJson);
      setSurveysLoading(false);
    })
  }

  const handleReportDownload = (survey_code, company) => {
    setReportLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/report/${survey_code}`, {
      method: "GET",
      credentials: "include"
    })
    .then(response => {
      if (response.ok) {
        return response.blob();  // Get the response as a Blob
      }
      throw new Error('Network response was not ok.');
    })
    .then(blob => {
      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${company}_KORE.pdf`; // You can set a default file name here
      document.body.appendChild(a);
      a.click();
  
      // Cleanup
      a.remove();
      window.URL.revokeObjectURL(url);
      setReportLoading(false);
    })
    .catch(exception => {
      setReportLoading(false);
      toast({
        status: "error",
        title: "Erro ao exportar o relatório",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
    });
  }
  

  useEffect(() => {
    handleContactLoad();
    handleSurveyLoad();
  }, []);

  const fontSize = useBreakpointValue({
    base: 20,
    md: 10,
    lg: 14,
  });

  const tableHeader = (
    text,
    freeze = false,
    alt = false,
  ) => {
    return (
      <Th 
        color="var(--ekora-prussian-blue)"
        bg={alt ? "var(--ekora-tangerine-alt)" : "var(--ekora-cerulean-alt)"}
        left={freeze && 0}
        zIndex={freeze ? 2 : 1}
        fontSize={fontSize} 
        top={0}
        position="sticky"
        fontFamily="'Gantari', sans-serif"
        rounded={0}
      >
        {text}
      </Th>
    )
  }

  const copyCode = (code) => {
    toast({
      status: "info",
      title: "Código copiado",
      description: "O código foi copiado para a área de transferência",
      isClosable: true,
      position: "top",
      duration: 3000,
      colorScheme: "cyan"
    });
    navigator.clipboard.writeText(code);
  }

  const copyUrl = (code) => {
    toast({
      status: "info",
      title: "Link copiado",
      description: "O link da aplicação foi copiado para a área de transferência",
      isClosable: true,
      position: "top",
      duration: 3000,
      colorScheme: "cyan"
    });
    navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL}/survey?code=${code}`);
  }

  const tableButton = (
    content, 
    onClick, 
    isDisabled = false,
    isLoading = false
  ) => {
    return (
      <Button
        bg="rgba(235,235,235,0.7)"
        fontSize={fontSize}
        onClick={onClick}
        padding={2}
        margin="5px"
        _hover={{
          bg: "var(--ekora-tangerine)",
          color: "var(--text-color)"
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        {content}
      </Button>
    )
  }

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);
  };

  return (
    <Flex
      as={motion.div}
      opacity={0.01}
      animate={{opacity: 1}}
      bg="gray.100"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <Sidenav 
        handleLogout={handleLogout} 
        contacts={contacts} 
        setContacts={setContacts}
        setSurveys={setSurveys}
        svIsOpen={svIsOpen}
        svOpen={svOpen}
        svClose={svClose}
        svUpdated={svUpdated}
        setSvUpdated={setSvUpdated}
        setSurveyData={setSurveyEdit}
        surveyData={surveyEdit}
        setContactData={setContactEdit}
        contactData={contactEdit}
        cttIsOpen={cttIsOpen}
        cttOpen={cttOpen}
        cttClose={cttClose}
        cttUpdated={cttUpdated}
        setCttUpdated={setCttUpdated}
      />

      <Flex 
        width={useBreakpointValue({
          base: "100%",
          md: "calc(100% - 150px)",
          lg: "calc(100% - 190px)"
        })}
      >
        <Box
          height="100%"
          width="65%"
        >
          <HStack
            padding="10px"
            spacing="10px"
            height="15%"
            minHeight="70px"
          >
            <Box
              bg="white"
              className="shadowed"
              height="100%"
              width="33%"
              rounded="5px"
              color="var(--ekora-prussian-blue)"
              fontSize={fontSize}
              padding="16px"
            >
              <Text fontWeight={600}>Aplicações criadas</Text>
              <Text fontSize={fontSize + 8}>{surveys.length}</Text>
            </Box>
            <Box
              bg="white"
              className="shadowed"
              height="100%"
              width="33%"
              rounded="5px"
              color="var(--ekora-prussian-blue)"
              fontSize={fontSize}
              padding="16px"
            >
              <Text fontWeight={600}>Aplicações ativas</Text>
              <Text fontSize={fontSize + 8}>{surveys.filter(survey => survey.status === "ATIVO").length}</Text>
            </Box>
            <Box
              bg="white"
              className="shadowed"
              height="100%"
              width="33%"
              rounded="5px"
              color="var(--ekora-prussian-blue)"
              fontSize={fontSize}
              padding="16px"
            >
              <Text fontWeight={600}>Total de respostas</Text>
              <Text fontSize={fontSize + 8}>{surveys.map(survey => survey.responses).reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</Text>
            </Box>
          </HStack>
          
          <Box 
            fontSize={fontSize}
            color="var(--ekora-prussian-blue)"
            padding="10px"
            overflow="hidden"
            height="85%"
          >
            <HStack
              padding="10px"
              textOverflow="hidden"
              height="5%"
              minHeight="30px"
              bg="var(--ekora-cerulean)"
              color="var(--text-color)"
              roundedTop="5px"
            >
              <FontAwesomeIcon width={fontSize + 1} icon={faListDots}/>
              <Text 
                fontSize={fontSize + 1}
                fontWeight={600}
              >
                APLICAÇÕES
              </Text>
            </HStack>
            <TableContainer 
              height="95%"
              bg="white"
              roundedBottom="5px"
              className="shadowed"
              minHeight={"calc(95% - 30px)"}
              width="100%"
              overflowY="auto"
              position="relative"
              fontSize={fontSize}
            >
              <Table>
                <Thead bg="var(--ekora-cerulean-alt)">
                  <Tr  >
                    {tableHeader("Empresa", true)}
                    {tableHeader("Contato")}
                    {tableHeader("Respondentes")}
                    {tableHeader("Criado em")}
                    {tableHeader("Termina em")}
                    {tableHeader("Código")}
                    {tableHeader("Status")}
                    {tableHeader("")}
                  </Tr>
                </Thead>
                <Tbody>
                  {!surveysLoading ?
                    surveys.map((survey, index) => {
                      const survey_contact = contacts.find(contact => contact.id === survey.contact_id);
                      return (
                        <>
                        { survey_contact && (
                          <Tr key={index} bg="white">
                            <Td className="sticky-column" style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 }}>
                              {survey_contact.company}
                            </Td>
                            <Td>{survey_contact.firstname} {survey_contact.lastname}</Td>
                            <Td>{survey.responses}</Td>
                            <Td>{formatDate(survey.created_in)}</Td>
                            <Td>{survey.status !== "ENCERRADO" ? formatDate(survey.expires_in) : "-"}</Td>
                            <Td>
                              {tableButton(
                                <><Text mr="7px">{survey.survey_code}</Text> <FontAwesomeIcon icon={faCopy} /></>, 
                                () => copyCode(survey.survey_code)
                              )}
                              {tableButton(<FontAwesomeIcon icon={faLink} />, () => copyUrl(survey.survey_code))}
                            </Td>
                            <Td fontWeight={800} color={survey.status === "ATIVO" ? "green.500" : "var(--ekora-cerulean)"}>{survey.status}</Td>
                            <Td>
                              {tableButton(<FontAwesomeIcon icon={faEdit} />, () => handleSurveyEdit(survey.id))}
                              {survey.status !== "ENCERRADO" && tableButton(<FontAwesomeIcon icon={faClose} />, () => handleSurveyClose(survey.id), false, svClosing)}
                              {(survey.status === "ENCERRADO" && survey.responses > 0) && tableButton(<FontAwesomeIcon icon={faDownload} />, () => handleReportDownload(survey.survey_code, survey_contact.company), false, reportLoading)}
                              {survey.status === "ENCERRADO" && tableButton(<FontAwesomeIcon icon={faTrashAlt} />, () => handleSurveyDelete(survey.id), false, svDeleting)}
                            </Td>
                          </Tr>
                        )}
                        </>
                      );
                    })
                    :
                    <Text color="rgba(0,0,0,0.4)">Carregando dados...</Text>
                  }
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
    
              
        <Box
          fontSize={fontSize}
          color="var(--ekora-prussian-blue)"
          padding="10px"
          overflow="hidden"
          width="35%"
          height="100%"
        >
          <HStack
            padding="10px"
            height="5%"
            minHeight="30px"
            bg="var(--ekora-tangerine)"
            color="var(--text-color)"
            roundedTop="5px"
            position="sticky"
            top={0}
            zIndex={3}
          >
            <FontAwesomeIcon width={fontSize + 1} icon={faUsers} />
            <Text fontSize={fontSize + 1} fontWeight={600}>
              CONTATOS
            </Text>
          </HStack>

          <TableContainer 
            height="95%"
            bg="white"
            roundedBottom="5px"
            className="shadowed"
            minHeight={"calc(95% - 30px)"}
            width="100%"
            overflowY="auto"
            position="relative"
            fontSize={fontSize}
          >
            <Table>
              <Thead>
                <Tr>
                  {tableHeader("Nome", true, true)}
                  {tableHeader("Email", false, true)}
                  {tableHeader("Telefone", false, true)}
                  {tableHeader("Empresa", false, true)}
                  {tableHeader("", false, true)}
                </Tr>
              </Thead>
              <Tbody>
                {!contactsLoading ?
                  contacts.map((contact, index) => (
                    <Tr key={index} bg="white">
                      <Td className="sticky-column" style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 }}>
                        {contact.firstname} {contact.lastname}
                      </Td>
                      <Td>{contact.email}</Td>
                      <Td>{contact.phone}</Td>
                      <Td>{contact.company}</Td>
                      <Td>
                        {tableButton(<FontAwesomeIcon icon={faEdit} />, () => handleContactEdit(contact.id))}
                        {tableButton(
                          <FontAwesomeIcon icon={faTrashAlt} />, 
                          () => handleContactDelete(contact.id), 
                          surveys.some(survey => survey.contact_id === contact.id),
                          cttDeleting
                        )}
                      </Td>
                    </Tr>
                  ))
                  :
                  <Text color="rgba(0,0,0,0.4)">Carregando dados...</Text>
                }
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Flex>
    </Flex> 
  )
}

export default MainPage