// Global imports
import { useEffect, useState } from "react";
import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// App-level imports
import AdminPage from "./admin/pages/AdminPage";
import LandingPage from "./common/pages/LandingPage";
import Survey from "./common/pages/Survey";
import NotFoundPage from "./common/pages/NotFoundPage";

function App() {
  const [adminLogged, setAdminlogged] = useState(false);

  const handleAdminLogin = () => {
    setAdminlogged(true)
  }

  const handleAdminLogout = () => {
    setAdminlogged(false);
    const cookies = document.cookie.split(";");

    cookies.forEach(cookie => {
      const cookieName = cookie.split("=")[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    });
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
    })
    .then(response => {
      if (response.ok) {
        handleAdminLogin();
      }
    })
  }, [])

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route exact path="/admin" element={
            <AdminPage 
              adminLogged={adminLogged}
              handleLogin={handleAdminLogin}
              handleLogout={handleAdminLogout}
            />
          } />
          <Route path="/" element={<LandingPage /> }/>
          <Route path="/survey" element={<Survey /> }/>
          <Route path="*" element={<NotFoundPage /> }/>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
