// Global Imports
import { Flex, useBreakpointValue, Box, Image, Text, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

// App-level imports
import '../../App.css'
import whiteLogo from "../../common/assets/LA_1080.png";

function ThankYouPage () {
  const navigate = useNavigate();

  const fontSize = useBreakpointValue({
    base: 20,
    md: 16,
    lg: 20,
  });

  const logoHeight = useBreakpointValue({
    base: "40px",
    md: "40px",
    lg: "55px"
  })

  return (
    <Flex 
      as={motion.div}
      opacity={0.01}
      animate={{opacity: 1}}
      transition={{duration: 3}}
      flexDirection="column"
    >
      <Box
        as={motion.div}
        height={0}
        opacity={0.01}
        animate={{opacity: 1, height: logoHeight}}
        transition={{duration: 3}}
        overflow="hidden"
      >
        <Image
          height={logoHeight}
          src={whiteLogo}
        />
      </Box>
      <Text
        fontWeight={400}
        fontSize={fontSize + 4}
        mt="15px"
        color="var(--ekora-prussian-blue)"
      >
        Muito obrigado!
      </Text>

      <Box
        mt="10px"
        padding="10px"
        rounded="5px"
        bg="rgba(0,0,0,0.1)"
        fontWeight={300}
        maxW="600px"
        fontSize={fontSize -2}
      >
        Muito obrigado por dedicar seu tempo para responder o Diagnóstico KORE.
      </Box>

      <Box
        mt="10px"
        padding="10px"
        rounded="5px"
        bg="rgba(0,0,0,0.1)"
        fontWeight={300}
        maxW="600px"
        fontSize={fontSize -2}
      >
        Suas respostas são valiosas e nos ajudarão a avaliar o índice de prontidão da sua empresa para a construção de resultados.
      </Box>

      <Box
        mt="10px"
        padding="10px"
        rounded="5px"
        bg="rgba(0,0,0,0.1)"
        fontWeight={300}
        maxW="600px"
        fontSize={fontSize -2}
      >
        Em breve a sua empresa receberá um relatório completo com os resultados do KORE.
      </Box>
      
      <Button
        mt="20px"
        bg="var(--ekora-cerulean)"
        color="white"
        _hover={{bg: "var(--ekora-cerulean-alt)"}}
        onClick={() => navigate("/")}
      >
        <Text mr="5px">Voltar ao início</Text>
        <FontAwesomeIcon width={fontSize} icon={faArrowRight}/>
      </Button>
    </Flex>
  )
}

export default ThankYouPage;