import { Box, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import InputMask from 'react-input-mask';

function PhoneInput ({ 
  fontSize = 12,
  placeholder = "(99) 99999-9999",
  onChange = null,
  value = ""
}) {
  const [phone, setPhone] = useState(value);
  const [error, setError] = useState('');

  const validatePhone = (phone) => {
    const re = /^\(\d{2}\) \d{5}-\d{4}$/;
    return re.test(phone);
  };

  const handleChange = (e) => {
    if (phone && !validatePhone(e.target.value)) {
      setError('Número de telefone inválido');
      onChange('phone', '');
    } else {
      if (e.target.value !== '(__) _____-____') {
        setError('');
        onChange('phone', e.target.value);
      }
    }

    if (e.target.value !== '(__) _____-____') {
      setPhone(e.target.value);
    } else {
      setPhone('');
    }
  };

  const handleBlur = () => {
    if (phone && !validatePhone(phone)) {
      setError('Número de telefone inválido');
      onChange('phone', '');
    } else {
      setError('');
      if (phone !== '(__) _____-____') {
        onChange('phone', phone);
      } else {
        onChange('phone', '');
      }
      
    }
  };

  return (
    <Box>
      <InputMask
        mask="(99) 99999-9999"
        value={phone}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {() => <Input 
          fontSize={fontSize} 
          placeholder={placeholder} 
          />
        }
      </InputMask>
      {error && <Text 
        fontSize={fontSize} 
        padding="10px" 
        pl="16px" 
        marginTop="5px"
        marginBottom="-15px" 
        color="white" 
        background="red.600" 
        rounded="5px"
      >{error}</Text>}
    </Box>
  );
};

export default PhoneInput