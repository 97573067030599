import { useState } from "react";
import { 
  Flex, 
  Select, 
  Box, 
  Button, 
  Text, 
  useBreakpointValue, 
  HStack, 
  VStack, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton, 
  useDisclosure 
} from '@chakra-ui/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import useBeforeUnload from "../functions/useBeforeUnload";

function QuestionPage ({
  questions,
  setQuestions,
  handleSubmit
}) {
  useBeforeUnload(true);
  
  const [current, setCurrent] = useState(0);
  const [direction, setDirection] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (event) => {
    const newIndex = Number(event.target.value) - 1;
    setDirection(newIndex > current ? 1 : -1);
    setCurrent(newIndex);
  };

  const fontSize = useBreakpointValue({
    base: 20,
    md: 16,
    lg: 20,
  });

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? "100%" : "-100%",
      opacity: 0,
    }),
  };

  const containerWidth = useBreakpointValue({
    base: "250px",
    md: "400px",
    lg: "500px",
    xl: "650px"
  });

  const containerHeigth = useBreakpointValue({
    base: `${100  + 48 + (fontSize * 2) + 24}px`,
    md: `${110  + 48 + (fontSize * 2) + 24}px`,
    lg: `${120  + 48 + (fontSize * 2) + 24}px`,
    xl: `${140  + 48 + (fontSize * 2) + 24}px`,
  });

  const questionHeigth = useBreakpointValue({
    base: "100px",
    md: "110px",
    lg: "120px",
    xl: "140px"
  });

  return (
    <Flex 
      as={motion.div}
      opacity={0.01}
      animate={{ opacity: useBreakpointValue({ base: 0, md: 1 }) }}
      transition={{ duration: 3 }}
      flexDirection="column"
      width={containerWidth}
      position="relative"
      overflow="hidden"
    >
      <VStack>
        <Select
          border="none"
          bg="var(--ekora-cerulean)"
          _hover={{ bg: "var(--ekora-cerulean-alt)", cursor: "pointer" }}
          color="white"
          fontWeight={500}
          fontSize={fontSize + 2}
          value={current + 1}
          onChange={handleChange}
        >
          {Array.from({ length: questions.length }, (_, i) => i + 1).map((num) => (
            <option key={num} value={num}>
              Pergunta <b>{num}</b>
            </option>
          ))}
        </Select>

        <Box 
          position="relative"
          width="100%" 
          height={containerHeigth}
        >
          <AnimatePresence
            initial={false}
            custom={direction}
          >
            <motion.div
              key={current}
              custom={direction}
              initial="enter"
              animate="center"
              exit="exit"
              variants={variants}
              transition={{ duration: 0.3 }}
              style={{ 
                position: 'absolute',
                width: '100%', 
                top: 0, 
                left: 0 
              }}
            >
              <Box
                padding="12px"
                rounded="5px"
                fontWeight={300}
                width={containerWidth}
                bg="rgba(0,0,0,0.05)"
                height={questionHeigth}
                overflow="auto"
                fontSize={fontSize}
              >
                {questions[current].question_text}
              </Box>

              <HStack width="100%" marginTop="10px">
                {Array.from({ length: 5 }, (_, i) => i + 1).map((num) => (
                  <>
                    <Button
                      key={num}
                      width="12%"
                      padding="24px"
                      fontSize={fontSize}
                      border="solid 2px var(--ekora-tangerine)"
                      bg={questions[current].value !== num ? "none" : "var(--ekora-tangerine)"}
                      _hover={{ bg: "var(--ekora-tangerine-alt)", border: "solid 2px var(--ekora-tangerine-alt)", color: "white" }}
                      color={questions[current].value !== num ? "var(--ekora-tangerine)" : "white"}
                      onClick={() => setQuestions(prevValues => 
                        prevValues.map((item, index) =>
                          index === current ? { ...item, value: num } : item
                        )
                      )}
                    >
                      {num}
                    </Button>
                    { num <= 4 && (
                      <Box 
                        key={`line-${num}`}
                        width="8%" 
                        height="2px" 
                        rounded="5px" 
                        bg="var(--ekora-tangerine)"
                      />
                    )}
                  </>
                ))}
              </HStack>

              <HStack
                mt="16px"
                width={containerWidth}
                overflow="auto"
                color="rgba(0,0,0,0.3)"
                fontSize={fontSize - 4}
              >
                <Text
                  width="30%"
                  textAlign="left"
                >
                  Discordo totalmente
                </Text>
                <Box 
                  width="40%" 
                  height="2px" 
                  rounded="5px" 
                  bg="rgba(0,0,0,0.3)"
                />
                <Text
                  width="30%"
                  textAlign="right"
                >
                  Concordo totalmente
                </Text>
              </HStack>
            </motion.div>
          </AnimatePresence>
        </Box>
      </VStack>

      <HStack
        mt="24px"
      >
        <Button
          width="50%"
          _hover={{ bg: "rgba(0,0,0,0.05)" }}
          onClick={() => {
            setDirection(-1);
            setCurrent(current - 1);
          }}
          isDisabled={current <= 0}
          fontSize={fontSize - 2}
        >
          <FontAwesomeIcon width={fontSize} icon={faArrowLeft}/>
          <Text ml="5px">Anterior</Text>
        </Button>

        <Button
          width="50%"
          _hover={current >= questions.length - 1 ? {bg: "var(--ekora-cerulean-alt)"} : { bg: "rgba(0,0,0,0.05)" }}
          onClick={() => {
            if (current >= questions.length - 1) {
              onOpen();
            } else {
              setDirection(1);
              setCurrent(current + 1);
            }
          }}
          fontSize={fontSize - 2}
          bg={current >= questions.length - 1 && "var(--ekora-cerulean)"}
          color={current >= questions.length - 1 && "white"}
        >
          <Text mr="5px">{current >= questions.length - 1 ? "Finalizar" : "Próximo"}</Text>
          <FontAwesomeIcon width={fontSize} icon={faArrowRight}/>
        </Button>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          fontFamily="'Gantari', sans-serif"
        >
          <ModalHeader>Enviar respostas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Deseja enviar suas respostas?
            Depois de enviadas, você não poderá alterar nenhuma delas.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" bg="red.200" mr={3} onClick={onClose}>
              Voltar
            </Button>
            <Button 
              variant="ghost"
              _hover={{bg: "var(--ekora-cerulean)", color: "white"}} 
              onClick={() => {
                onClose();
                handleSubmit();
              }}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      
    </Flex>
  );
}

export default QuestionPage;
